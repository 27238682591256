import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ErrorLabelPipe } from './error-label.pipe';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
    standalone: true,
    selector: 'ui-v2-input-error',
    template: `
        @if (showError) {
            <div class="mt-1.5 flex flex-row text-text-error">
                <div inlineSVG="https://assets.smooved.be/icons/v2/16/circle-alert.svg" class="mr-1 mt-0.5 text-text-error"></div>
                <p class="text-body-sm">{{ error | errorLabel }}</p>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [InlineSVGModule, ErrorLabelPipe],
})
export class ErrorComponent {
    @Input() showError = false;
    @Input() error: string;
}
