import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ArrayUtils } from '@core/utils';

export class BaseInput<T> implements ControlValueAccessor {
    public controlContainer: ControlContainer;
    public innerModel: T;
    public formControl: FormControl;
    public formControlName: string;
    public innerDisabled: boolean;

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }

    public get showError(): boolean {
        return (
            this.abstractControl?.invalid &&
            (this.controlContainer?.['submitted'] || this.abstractControl.touched || this.abstractControl.dirty)
        );
    }

    public get error(): string {
        if (!this.abstractControl) {
            return null;
        }

        return ArrayUtils.first(Object.keys(this.abstractControl.errors || {}));
    }

    public onBlur(): void {
        this.propagateTouched();
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.propagateTouched = fn;
    }

    public writeValue(_: any): void {}

    public propagateChange = (_: any) => {};
    public propagateTouched = () => {};

    protected get abstractControl(): AbstractControl {
        if (this.formControl) return this.formControl;
        const control = this.controlContainer?.control;
        if (!control) {
            return null;
        }

        // if formArray, get control by index
        if (control['controls']?.length) {
            return control['controls'][this.formControlName];
        }
        return control?.get(this.formControlName);
    }
}

export function controlContainerFactory(formGroupDirective: FormGroupDirective | null, ngForm: NgForm | null): ControlContainer | null {
    return formGroupDirective || ngForm;
}
