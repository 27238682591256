import { NgClass, NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ModalService } from '@ui-v2/molecules/modal/modal.service';
import { MODAL_OUT_DURATION } from '@ui-v2/styles/themes/smooved/animation-constants';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { animationFrameScheduler, fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
    selector: 'ui-v2-modal',
    standalone: true,
    templateUrl: './modal.component.html',
    imports: [NgOptimizedImage, NgClass, InlineSVGModule],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('modal') modal!: ElementRef<HTMLDialogElement>;
    @ViewChild('modalContent') modalContent!: ElementRef<HTMLDivElement>;
    @Input() public showHelpButton = false;
    @Input() public title: string;

    @Output() public helpClicked: EventEmitter<void> = new EventEmitter();

    public showScrollIndicator = false;
    public hasOverflow = false;
    public isClosing = false;

    private scrollSubscription!: Subscription;

    constructor(
        private readonly modalService: ModalService,
        private readonly cdr: ChangeDetectorRef,
        private readonly ngZone: NgZone
    ) {}

    public ngOnInit(): void {
        // Initialize overflow state after view initialization.
        setTimeout(() => {
            this.checkScroll();
            this.cdr.detectChanges();
        });
    }

    public ngAfterViewInit(): void {
        // Subscribe to scroll events outside Angular to prevent excessive change detection.
        this.ngZone.runOutsideAngular(() => {
            this.scrollSubscription = fromEvent(this.modalContent.nativeElement, 'scroll')
                .pipe(throttleTime(16, animationFrameScheduler))
                .subscribe(() => {
                    // Re-enter Angular zone only when needed to update bindings.
                    this.ngZone.run(() => this.checkScroll());
                });
        });
        this.open();
    }

    public ngOnDestroy(): void {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }

    public open(): void {
        this.modal.nativeElement.showModal();

        // Use a small delay to ensure the dialog is fully rendered.
        setTimeout(() => {
            this.modal.nativeElement.focus();
        }, 0);
    }

    public close(): void {
        this.isClosing = true;

        // Wait for animation to complete before closing
        setTimeout(() => {
            this.modal.nativeElement.close();
            this.modalService.close();
            this.isClosing = false;
        }, MODAL_OUT_DURATION);
    }

    public onKeydown(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            const target = event.target as HTMLElement;
            const isFormField =
                target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.tagName === 'SELECT' || target.isContentEditable;
            if (isFormField) {
                event.preventDefault();
                return;
            }
            event.preventDefault();
            this.close();
        }
    }

    // Clicking on the backdrop closes the modal.
    public onBackdropClick(event: MouseEvent): void {
        if (event.target === this.modal.nativeElement) {
            this.close();
        }
    }

    // Removed the direct template binding to onContentScroll.
    // The throttled scroll listener now handles updating the scroll state.
    private checkScroll(): void {
        if (!this.modalContent) {
            return;
        }

        const element = this.modalContent.nativeElement;
        const isScrollable = element.scrollHeight > element.clientHeight;
        const isScrolledToBottom = Math.ceil(element.scrollTop + element.clientHeight) >= element.scrollHeight;

        this.hasOverflow = isScrollable;
        this.showScrollIndicator = isScrollable && !isScrolledToBottom;
    }

    public scrollToBottom(event?: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.modalContent.nativeElement.scrollTo({
            top: this.modalContent.nativeElement.scrollHeight,
            behavior: 'smooth',
        });
    }
}
