<dialog tabindex="0" #modal class="modal modal-top" (keydown)="onKeydown($event)" (click)="onBackdropClick($event)">
    <div
        #modalContent
        class="modal-box"
        [ngClass]="{
            'rounded-xl': !hasOverflow,
            'rounded-t-xl rounded-b-none': hasOverflow,
            closing: isClosing,
        }"
    >
        <div class="m-4">
            <header class="mb-4 flex justify-between">
                @if (title) {
                    <div class="min-h-10 flex items-center">
                        <p class="text-title-lg">{{ title }}</p>
                    </div>
                }
                <div class="ml-auto flex gap-2">
                    <ng-content select="[header-options]"></ng-content>
                    @if (showHelpButton) {
                        <button type="button" (click)="helpClicked.emit()" class="btn btn-circle btn-secondary">
                            <div class="text-icon-primary" [inlineSVG]="'https://assets.smooved.be/icons/v2/20/question.svg'"></div>
                        </button>
                    }
                    <button type="button" (click)="close()" class="btn btn-circle btn-secondary">
                        <img class="fill-icon-primary" ngSrc="https://assets.smooved.be/icons/v2/20/x.svg" alt="" width="20" height="20" />
                    </button>
                </div>
            </header>
            <ng-content></ng-content>
        </div>
        <div class="bg-linear-to-b from-transparent to-15% to-bg-card-default sticky bottom-0 w-full flex justify-end">
            <button
                data-testid="scroll-indicator-button"
                class="absolute -top-10 right-6 btn btn-primary btn-circle shadow-sm transition-all duration-200 ease-in-out origin-center"
                [ngClass]="{
                    'scale-100 opacity-100': showScrollIndicator,
                    'scale-0 opacity-0': !showScrollIndicator,
                }"
                (click)="scrollToBottom($event)"
            >
                <div [inlineSVG]="'https://assets.smooved.be/icons/v2/20/arrow-down.svg'"></div>
            </button>
            <div class="p-4 w-full empty:hidden flex flex-col gap-2">
                <ng-content select="[modal-actions]"></ng-content>
            </div>
        </div>
    </div>
</dialog>
