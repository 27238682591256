import { ChangeDetectorRef, inject, Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@core/utils';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'errorLabel',
    standalone: true,
    pure: false,
})
export class ErrorLabelPipe extends TranslatePipe implements PipeTransform {
    constructor() {
        super(inject(TranslateService), inject(ChangeDetectorRef));
    }

    transform(value: string, interpolateParams: object) {
        return super.transform(`V2.INPUT.VALIDATORS.${StringUtils.toUpperSnakeCase(value)}`, interpolateParams);
    }
}
