import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'ui-v2-fieldset',
    template: `
        <fieldset class="fieldset">
            @if (legend) {
                <legend class="fieldset-legend">{{ legend }}</legend>
            }
            <ng-content></ng-content>
            @if (label) {
                <label class="fieldset-label">{{ label }}</label>
            }
        </fieldset>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsetComponent {
    @Input() legend: string;
    @Input() label: string;
}
